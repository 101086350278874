import api from './api'
export default { 
    addNetworkType(data) {
        return api.execute(`post`,`/moderator/nettype`, data)
    },
    updateNetworkType(data) {
        return api.execute(`put`,`/moderator/nettype`, data)
    },
    getNetworkType() {
        return api.execute('get', `/moderator/nettype`)
    },
    removeNetworkType(data) {
        return api.execute('delete', `/moderator/nettype`,data)
    },
    getAllNetworkType(country) {
        return api.execute('get', `/nettype?country=${country ? country : ''}`)
    },
    getCountry(){
        return api.execute('get', `/country`)
    }

}