import api from './api'

export default {
    addCarrier(data){
    return api.execute(`post`,`/manager/carrier`, data)
    },
    getCarriers(){
        return api.execute(`get`,`/manager/carrier`)
    },
    deleteCarrier(data){
        return api.execute(`delete`,`/manager/carrier`, data)
    },
    editCarrier(data){
        return api.execute(`put`,`/manager/carrier`,data)
    }
}
